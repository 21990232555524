import { FleetGraphProps } from "../../types/FleetGraphProps";
import { ReactElement, useEffect, useRef, useState } from "react";
import { FleetGraphDataItem } from "../../types/FleetGraphDataItem";
import { useTranslation } from "react-i18next";
import Plot from 'react-plotly.js';
import { Data, PlotData } from "plotly.js";
import {errorToast, getVesselGrade, GradeType} from "@idwal/idwal-react-components";
import "./FleetGraph.css"
import { getBackgroundScatterPlotData, getGradeAverageData, getHoverText, getSteppedBackgroundAverageData, getVesselGradeScatterPlotData, getVesselGradeScatterPlotDataByVessel, groupByTechnicalManager, hasNoGradeData, resetGraphIcon, setGraphLayout } from "../../utils/FleetGraphHelper";
import { GlobalFilterValue } from "../../types/GlobalFilterValue";
import { applyGlobalFilter, applyGlobalFilterWithoutVesselName } from "../Shared/GlobalFilter";
import { INSPECTION_STATUS_FULL_REPORT } from "../../utils/Constants";
import {
    calculateAverage,
    getGrade,
    getMaxGrade,
    getMinGrade,
    hasGrade
} from "./FleetGraphGradeFunctions";
import { LoadingGraph } from "../Loading/LoadingGraph";
import { GraphType, buildGradeGraphTitle } from "../../utils/buildGradeGraphTitle";
import { applyBackgroundAdvancedFilters, applyForegroundAdvancedFilters } from "../../utils/AdvancedFilterHelper";
import { useCapabilities } from "../../hooks/useCapabilities";
import { Capability } from "../../types/Capability";
import { getTotalFilterCount } from "../../utils/FilterInformation";
import { FilterButtonRow } from "../FilterButtonRow/FIlterButtonRow";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectGlobalFilters, selectGraphContextFilter } from "../../store/selectors/filtersSelectors";
import { selectAiGradingActive, selectSelectedGroupName } from "../../store/selectors/userSelectors";
import { selectAdvancedFilters } from "../../store/selectors/advancedFiltersSelectors";
import { selectFleetGraphData, selectFleetGraphLoading } from "../../store/selectors/fleetGraphSelectors";
import { selectFleet } from "../../store/selectors/fleetSelectors";
import { GraphFilterType } from "../GraphFilterSelector/GraphFilterSelector";
import { useFlags } from "launchdarkly-react-client-sdk";

const BACKGROUND_COUNT_MINIMUM = 20;

export const FleetGraph = (props: FleetGraphProps): ReactElement => {
    const [fleetFilteredBackgroundGraphData, setFleetFilteredBackgroundGraphData] = useState<FleetGraphDataItem[] | undefined>(undefined);
    const [marketFilteredBackgroundGraphData, setMarketFilteredBackgroundGraphData] = useState<FleetGraphDataItem[] | undefined>(undefined);
    const [filteredData, setFilteredData] = useState<FleetGraphDataItem[] | undefined>(undefined);
    const [layout, setLayout] = useState<any>(null);

    const [graphLoaded, setGraphLoaded] = useState<boolean>(false);

    const {t} = useTranslation("locale");

    const globalFilters = useAppSelector(selectGlobalFilters);
    const selectedGroupName = useAppSelector(selectSelectedGroupName);
    const aiGradingActive = useAppSelector(selectAiGradingActive);
    const { 
        appliedFilters,
        totalForegroundFilterCount,
        totalBackgroundFilterCount,
    } = useAppSelector(selectAdvancedFilters);
    const graphContextFilter = useAppSelector(selectGraphContextFilter);

    const [ globalFilter, setGlobalFilter ] = useState<GlobalFilterValue>({});

    const defaultMinAge = useRef(0);
    const defaultMaxAge  = useRef(0);
    const defaultMinGrade = useRef(0);
    const defaultMaxGrade  = useRef(100);


    const {
        releaseGradeChartEnhancement
    } = useFlags();

    useEffect(() => {
        if(globalFilters)
        {
            setGlobalFilter(globalFilters);
        }
    }, [globalFilters]);

    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_ADVANCED_BENCHMARKING
    ]);

    const totalFilterCount = getTotalFilterCount(
        totalForegroundFilterCount,
        totalBackgroundFilterCount
    );

    const filtersApplied = totalFilterCount > 0;
    const hasAdvancedBenchmarkingCapability = capabilities.CAP_FLEETAPP_ADVANCED_BENCHMARKING;

    let maxAge = 0;
    let minAge = 0;
    let minGrade = 0;
    let maxGrade = 100;

    const xAxisMinAge = 0;
    const xAxisThreshold = 25;
    const xAxisStepAmount = 5;

    const { 
        data: fleetResponse,
        fleetDataItems,
        error,
        loading,
        inspectionAndGradeData,
        inspectionAndGradeDataLoading,
    } = useAppSelector(selectFleet);
    
    const fleetGraphData = useAppSelector(selectFleetGraphData);
    const fleetGraphLoading = useAppSelector(selectFleetGraphLoading);

    const useFleetAsBackgroundData = 
        (hasAdvancedBenchmarkingCapability && !appliedFilters.marketDataToggleSelected) 

    const vesselHasAiGrades = (dataItem: FleetGraphDataItem) => Boolean(dataItem.grade_min) && Boolean(dataItem.grade_max);

    const applyFilterstoBackgroundData = (dataItem: FleetGraphDataItem) => {
        if (hasNoGradeData(dataItem, props.mode)) {
            return false
        };
        const vesselswithGlobalFiltersApplied = applyGlobalFilterWithoutVesselName(globalFilters, dataItem.vessel_type, dataItem.ship_age);

        if (useFleetAsBackgroundData) {
            const hasAiGrade = vesselHasAiGrades(dataItem);
            return vesselswithGlobalFiltersApplied && applyBackgroundAdvancedFilters(
                appliedFilters,
                {
                    aiGradingActive,
                    countryOfBuild: dataItem.build_country,
                    technicalManager: dataItem.technical_manager,
                    dwt: dataItem.dwt,
                    teu: dataItem.teu,
                    lastInspected: dataItem.last_inspected,
                    vesselHasAiGrades: hasAiGrade,
                    vessel: dataItem.vessel_name
                },
                t
            );
        } else {
            return vesselswithGlobalFiltersApplied;
        }
    }

    const applyFiltersToFleetData = (dataItem: FleetGraphDataItem) => {
        if (hasNoGradeData(dataItem, props.mode)) {
            return false
        }
        const hasAiGrade = vesselHasAiGrades(dataItem);
        return applyGlobalFilter(globalFilters, dataItem.vessel_name ?? "", dataItem.vessel_type, dataItem.technical_manager, dataItem.ship_age)
            && applyForegroundAdvancedFilters(
            appliedFilters,
{
                aiGradingActive,
                countryOfBuild: dataItem.build_country,
                technicalManager: dataItem.technical_manager,
                dwt: dataItem.dwt,
                teu: dataItem.teu,
                lastInspected: dataItem.last_inspected,
                vesselHasAiGrades: hasAiGrade
            },
            t
        );
    }

    useEffect(() => {
        if (!fleetDataItems.length) {
            return;
        }

        const graphDataItems = fleetDataItems.map(ship => {
            return {
                vessel_name: ship.vessel.vesselName,
                imo_number: ship.vessel.imo,
                vessel_type: ship.vessel.type,
                build_country: ship.vessel.countryOfBuild,
                ship_age: ship.vessel.shipAge,
                last_inspected: "",
                technical_manager: ship.vessel.technicalManager,
                dwt: ship.vessel.dwt,
                teu: ship.vessel.teu,
            };
        });
        if (props.mode === 'grade') {
            graphDataItems.forEach((fleetGraphDataItem: FleetGraphDataItem) => {
                if (fleetGraphDataItem.status !== INSPECTION_STATUS_FULL_REPORT) {
                    fleetGraphDataItem.grade = null;
                }
            });
        }

        if (inspectionAndGradeData) {
            graphDataItems.forEach((fleetDataItem: FleetGraphDataItem) => {

                const key = fleetDataItem.imo_number;
                const fleetDataFromApi = inspectionAndGradeData.fleetDataItems.find(item => item.vessel.imo === key);

                if (!key || !fleetDataFromApi) {
                    return;
                }

                if (fleetDataFromApi.inspection?.lastInspected) {
                    fleetDataItem.last_inspected = fleetDataFromApi.inspection.lastInspected.toString();
                }
                
                const grades = getVesselGrade(aiGradingActive, fleetDataFromApi.inspection?.idwalGrade ? fleetDataFromApi.inspection?.idwalGrade.toString() : undefined, fleetDataFromApi.inspection?.status ? fleetDataFromApi.inspection?.status.toString() : undefined, fleetDataFromApi.aiGrades!);

                switch (grades?.gradeType) {
                    case GradeType.IDWAL:
                        if (grades.grade) {
                            fleetDataItem.grade = grades.grade;
                        }

                        break;
                    case GradeType.PREDICTED:
                        if (grades.gradeMin && grades.gradeMax) {
                            fleetDataItem.grade_min = Math.round(grades.gradeMin);
                            fleetDataItem.grade_max = Math.round(grades.gradeMax);
                        }

                        break;
                    case GradeType.EVOLUTION:
                        if (grades.gradeMin && grades.gradeMax) {
                            fleetDataItem.grade_min = grades.gradeMin;
                            fleetDataItem.grade_max = grades.gradeMax;
                        } else if (grades.grade) {
                            fleetDataItem.grade = grades.grade;
                        }
                        break;
                }

                if (fleetDataFromApi.inspection?.idwalGrade) {
                    fleetDataItem.management_score = fleetDataFromApi.inspection?.managementScore;
                    fleetDataItem.condition_score = fleetDataFromApi.inspection?.conditionScore;
                }
            });

            const filteredForegroundData = graphDataItems.filter(applyFiltersToFleetData);

            
            setFilteredData(filteredForegroundData);

            const myFleetBackgroundData = [...graphDataItems];
            if (myFleetBackgroundData) {
                const filteredBgData = myFleetBackgroundData.filter(applyFilterstoBackgroundData);
                setFleetFilteredBackgroundGraphData(filteredBgData);
            }

            const marketBackgroundData = fleetGraphData;
            if (marketBackgroundData) {
                const filteredBgData = marketBackgroundData.filter(applyFilterstoBackgroundData);
                setMarketFilteredBackgroundGraphData(filteredBgData);
            }
        }
    }, [fleetResponse, fleetGraphData, inspectionAndGradeData, globalFilter, aiGradingActive, selectedGroupName, appliedFilters.foreground, appliedFilters.marketDataToggleSelected]);

    useEffect(() => {
        const filteredBackgroundGraphData = appliedFilters.marketDataToggleSelected ? marketFilteredBackgroundGraphData : fleetFilteredBackgroundGraphData;
        if (!fleetResponse || !filteredBackgroundGraphData || !filteredData) {
            return;
        }

        if (globalFilter?.vesselAgeBands && globalFilter.vesselAgeBands.length) {
            maxAge = Math.max(...globalFilter.vesselAgeBands.map(x => Number(x.split("-")[1])))
            minAge =  Math.min(...globalFilter.vesselAgeBands.map(x => Number(x.split("-")[0])))
        } else {
            maxAge = Math.ceil(Math.max(...filteredBackgroundGraphData.map(x => x.ship_age), ...filteredData.map(x => x.ship_age), maxAge));
        }

        if (filteredBackgroundGraphData) {
            const minGrades = [...filteredBackgroundGraphData, ...filteredData].map(x => getMinGrade(x, props.mode))
            const maxGrades = [...filteredBackgroundGraphData, ...filteredData].map(x => getMaxGrade(x, props.mode))

            minGrade =  Math.floor(Math.min(...minGrades));
            maxGrade =  Math.ceil(Math.max(...maxGrades));
        } else {
            const minGrades = filteredData.map(x => getMinGrade(x, props.mode));
            const maxGrades = filteredData.map(x => getMaxGrade(x, props.mode));

            minGrade =  Math.floor(Math.min(...minGrades));
            maxGrade =  Math.ceil(Math.max(...maxGrades));
        }

        if (!loading || !fleetGraphLoading || (filteredData.length && filteredBackgroundGraphData.length))
        {
            setLayout(
                setGraphLayout({
                    defaultXAxisMin: xAxisMinAge,
                    xAxisThreshold,
                    xAxisStepAmount,
                    yAxisMin: minGrade, 
                    yAxisMax: maxGrade, 
                    mode: props.mode,
                    fleetdata: filteredData,
                    backgroundData: filteredBackgroundGraphData,
                    filtersApplied
                })
            );
        }

        if (filteredData || filteredBackgroundGraphData) {
            defaultMaxAge.current = maxAge;
            defaultMinAge.current = minAge;
            defaultMaxGrade.current = maxGrade;
            defaultMinGrade.current = minGrade;
        }
    }, [filteredData, fleetFilteredBackgroundGraphData, marketFilteredBackgroundGraphData, globalFilter, selectedGroupName]);

    if (error) {
        errorToast(t("fleetGraph.errorMessage"));
        return <div />;
    }

    const filteredBackgroundGraphData = appliedFilters.marketDataToggleSelected ? marketFilteredBackgroundGraphData : fleetFilteredBackgroundGraphData;
    const dataIsLoading = loading || inspectionAndGradeDataLoading || fleetGraphLoading;

    if (dataIsLoading || filteredData === undefined || filteredBackgroundGraphData === undefined) {
        return (
            <><LoadingGraph /></>
        )
    }

    const backgroundPlotName = appliedFilters.marketDataToggleSelected
        ? t("fleetGraph.backgroundMarket")
        : t("fleetGraph.backgroundFleet");

    const backgroundData = getBackgroundScatterPlotData(
        filteredBackgroundGraphData, 
        props.mode, 
        backgroundPlotName
    );

    const fleetData = getVesselGradeScatterPlotData(
        filteredData, 
        props.mode, 
        t("fleetGraph.foregroundVessels")
    );

    const fleetDataByVessel = getVesselGradeScatterPlotDataByVessel(
        filteredData,
        props.mode
    ) as Array<Partial<PlotData>>

    const groupedByTechnicalManagerData = groupByTechnicalManager(filteredData);

    const technicalManagerPlots = Object.keys(groupedByTechnicalManagerData).map((technicalManager) => {
        const technicalManagerData = groupedByTechnicalManagerData[technicalManager];
        const plot = getVesselGradeScatterPlotData(
            technicalManagerData, 
            props.mode, 
            technicalManager
        );

        // Unset marker color for technical manager plots
        plot.marker!.color = undefined;
        if (props.mode === "grade") {
            plot.error_y = {
                type: 'data',
                symmetric: false,
                array: technicalManagerData.map(x => x.grade ? null : x.grade_max! - ((x.grade_min! + x.grade_max!)/2)),
                arrayminus: technicalManagerData.map(x => x.grade ? null : Math.abs(x.grade_min! - ((x.grade_min! + x.grade_max!)/2))),
                visible: true,
                thickness: 16,
                width: 0,
                opacity: 0.75
            }
    
            plot.marker!.opacity = technicalManagerData.map(x => x.grade != null ? 1: 0);
        }
        return plot;
    });

    const fleetAverageGrade = calculateAverage(
        filteredData.filter(x => hasGrade(x, props.mode,aiGradingActive))
            .map(x => getGrade(x, props.mode)))

    const fleetAveragePlot = getGradeAverageData(
        filteredData, filteredBackgroundGraphData, fleetAverageGrade);

    const benchmarkAverageGrade = calculateAverage(
        filteredBackgroundGraphData.filter(x => hasGrade(x, props.mode,aiGradingActive))
            .map(x => getGrade(x, props.mode)))

    const steppedAverageGraphData = getSteppedBackgroundAverageData(filteredBackgroundGraphData, props.mode)

    const traceMaxXAxis = Math.max(...filteredBackgroundGraphData.map(x => x.ship_age), ...filteredData.map(x => x.ship_age));

    const firstSteppedAverageYValue = steppedAverageGraphData[0].y[0];
    const firstSteppedAverageXValue1 = steppedAverageGraphData[0].x[0];

    const bgLabelTrace: Data = {
        x: [firstSteppedAverageXValue1 + 2.5],
        y: [firstSteppedAverageYValue + 1.25],
        showlegend: false,
        legendgroup: "bg",
        name: "bgLabel",
        visible: true,
        hoverinfo: 'skip',
        text: [t("fleetGraph.benchmarkAverage")],
        mode: 'text'
    };

    const fleetLabelTrace : Data = {
        x: [traceMaxXAxis-3],
        y: [fleetAverageGrade > benchmarkAverageGrade ? fleetAverageGrade+1.25 : fleetAverageGrade-1.25],
        showlegend: false,
        legendgroup: "fleet",
        name: "fleetLabel",
        hoverinfo: 'skip',
        text: [t("fleetGraph.fleetAverage")],
        mode: 'text'
    };

    let data : Data[] = [];

    if (
        graphContextFilter === GraphFilterType.VESSELS && 
        releaseGradeChartEnhancement && 
        globalFilters.vessels && 
        globalFilters.vessels.length > 0
    ) {
        data = [ backgroundData, ...fleetDataByVessel, fleetLabelTrace, fleetAveragePlot ];
    } else if (graphContextFilter === GraphFilterType.VESSELS){
        data = [ backgroundData, fleetData, fleetLabelTrace, fleetAveragePlot ];
    } else {
        data = [ backgroundData, ...technicalManagerPlots, fleetLabelTrace, fleetAveragePlot ];
    }

    if (backgroundData.x!.length) {
        data.push(bgLabelTrace, ...steppedAverageGraphData)
    }

    function onAfterPlot() {
        setGraphLoaded(true);
    }

    const hasFleetData = filteredData.some((fleetDataItem: FleetGraphDataItem) => fleetDataItem.grade);
    const hasAIData = filteredData.some((fleetDataItem: FleetGraphDataItem) => fleetDataItem.grade_min && fleetDataItem.grade_max);
    const backgroundCountTooLow =
        hasAdvancedBenchmarkingCapability && appliedFilters.marketDataToggleSelected ?
        filteredBackgroundGraphData.length <= BACKGROUND_COUNT_MINIMUM : false;

    const hasDataToRender = aiGradingActive ? (hasAIData || hasFleetData) : hasFleetData;
    const canRenderGraph = hasDataToRender && !backgroundCountTooLow;
    const noResultsMessage = backgroundCountTooLow ? t("fleetGraph.backgroundCountTooLow") : t("fleetGraph.noFleetDataDetected");

    const graphTitle = buildGradeGraphTitle({
        mode: GraphType.GRADE,
        globalFilterState: globalFilters,
        marketDataToggleSelected: appliedFilters.marketDataToggleSelected,
        t
    });

    const renderGraph = () => {
        if (canRenderGraph) {
            return (
                <>
                    <LoadingGraph hidden={graphLoaded} />
                    <div className={"graph-container m-0"} hidden={!graphLoaded} data-cy={"fleet-graph"}>
                    <FilterButtonRow
                        enableFilterButton={hasAdvancedBenchmarkingCapability}
                    />
                     <div className={"flex align-items-center"}>
                         <p className={"mb-3"}>
                             {graphTitle}
                         </p>
                     </div>
                        <Plot
                            useResizeHandler={true}
                            onAfterPlot={onAfterPlot}
                            layout={layout}
                            data={data}
                            style={{width: "100%", height: "100%"}}
                            config={{displayModeBar: true, responsive: true, displaylogo: false, scrollZoom: false, showTips: false,
                                modeBarButtonsToAdd: [
                                    {
                                        name: 'reset graph',
                                        icon: resetGraphIcon,
                                        title: "reset",
                                        click: function (gd) {
                                            setLayout(
                                                setGraphLayout({
                                                    defaultXAxisMin: xAxisMinAge,
                                                    xAxisThreshold,
                                                    xAxisStepAmount,
                                                    yAxisMin: minGrade, 
                                                    yAxisMax: maxGrade, 
                                                    mode: props.mode,
                                                    fleetdata: filteredData,
                                                    backgroundData: filteredBackgroundGraphData,
                                                    filtersApplied
                                                })
                                            );
                                        }
                                    }
                                ],
                                modeBarButtonsToRemove: ['pan2d','zoom2d','autoScale2d','resetScale2d' ,'lasso2d','select2d'] }}
                        />
                    </div>
                </>
            )
        } else {
            return (
                <div>
                    <FilterButtonRow
                        enableFilterButton={hasAdvancedBenchmarkingCapability}
                    />
                    <div className={"flex align-items-center"}>
                        <p className={"mb-3"}>
                            {graphTitle}
                        </p>
                    </div>
                    <div className="flex justify-content-center">
                        <p>{noResultsMessage}</p>
                    </div>
                </div>
            )
        }
    }

    return renderGraph();
}
