import { useTranslation } from "react-i18next";
import { MenuButton } from "../MenuButton/MenuButton";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { ScreeningItem } from "../../types/screening";
import { removeData, setProspects } from "../../store/slices/screeningSlice";
import { useMemo } from "react";
import { selectScreeningProspects } from "../../store/selectors/screeningSelectors";
import { updateScreeningProspects } from "../../services/ScreeningService";

export const ScreeningActionMenu = (item: ScreeningItem) => {
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const prospects = useAppSelector(selectScreeningProspects);

    const menuItems = useMemo(
        () => [
            {
                label: t("screening.removeFromList"),
                className: "screening-remove-ship",
                icon: <img className="pr-2" alt="remove_ship" src="./remove-ship.svg" />,
                command: () => {
                    const updated = prospects.filter((prospect) => prospect !== item.vessel_imo);
                    updateScreeningProspects(updated);

                    dispatch(setProspects(updated));
                    dispatch(removeData(item));
                }
            }
        ],
        [prospects]
    );

    return (
        <div className="flex align-items-center justify-content-center">
            <MenuButton menu={menuItems} />
        </div>
    );
};
