import { useLocation } from "react-router-dom";
import { GlobalFilter } from "./GlobalFilter";

const hiddenPaths = ['/rectification', '/rectifications', '/screening'];

export const FleetFilter = () => {
    const { pathname } = useLocation();

    const isHiddenPath = hiddenPaths.some((path) => pathname.endsWith(path));

    if (isHiddenPath || pathname === "/") {
        return null;
    }

    return <GlobalFilter />
}
