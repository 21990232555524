import { Route, Routes } from 'react-router-dom';
import { useCapabilities } from '../../hooks/useCapabilities';
import { Capability } from '../../types/Capability';
import { SecuredRoute } from '../Authentication/SecuredRoute';
import Fleets from '../../pages/Fleets';
import Defects from '../../pages/Defects';
import DefectRectification from '../../pages/DefectRectification';
import Grade from '../../pages/Grade';
import SubGradesPage from '../../pages/SubGrades';
import SharedDefects from '../../pages/SharedDefects';
import PortStateControl from '../../pages/PortStateControl';
import {DEFECTS, GRADE, PSC, RECTIFICATIONS, SUBGRADES, SCREENING } from "../../types/RouteNames";
import {useAppSelector} from "../../hooks/storeHooks";
import {MarketingPage} from "@idwal/idwal-react-components";
import {sendGAEvent} from "../../AnalyticsService";
import {
    MARKETING_PAGE_CATEGORY,
    MY_FLEET_LEARN_MORE_CLICK,
    MY_FLEET_REQUEST_DEMO_CLICK
} from "../../constants/analytics";
import {MarketingPageProps} from "@idwal/idwal-react-components/dist/MarketingPage/MarketingPage";
import {userIsTechnicalManager} from "../../services/UserService";
import {useAuthenticator} from "@aws-amplify/ui-react";
import Screening from '../../pages/Screening';

export const MainRoutes = () => {
    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_VIEW_DEFECTS,
        Capability.CAP_FLEETAPP_VIEW_FLEETS,
        Capability.CAP_FLEETAPP_VIEW_GRADES,
        Capability.CAP_FLEETAPP_VIEW_SUBGRADES,
        Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS,
        Capability.CAP_FLEETAPP_VIEW_PSC,
        Capability.CAP_FLEETAPP_VIEW_SCREENING,
    ]);

    const routes = [];
    const { user } = useAuthenticator((context) => [context.user]);

    const capabilitiesLoaded = useAppSelector(state => state.user.capabilitiesLoaded);

    const getMarketingPage = (type: MarketingPageProps["type"], learnMoreCallback: MarketingPageProps["learnMoreCallback"], requestDemoCallback: MarketingPageProps["requestDemoCallback"]) => {
        return <div className="h-full flex flex-column align-items-center justify-content-center">
            <div id={"marketing-container"}>
                <MarketingPage type={type} learnMoreCallback={learnMoreCallback} requestDemoCallback={requestDemoCallback} />
            </div>
        </div>
    }

    const fleetPage = capabilities[Capability.CAP_FLEETAPP_VIEW_FLEETS] ?
        <Fleets />
        :
        getMarketingPage("myFleet", () => sendGAEvent(MARKETING_PAGE_CATEGORY, MY_FLEET_LEARN_MORE_CLICK), () => sendGAEvent(MARKETING_PAGE_CATEGORY, MY_FLEET_REQUEST_DEMO_CLICK));

    const addFleetRoute = !userIsTechnicalManager(user) && capabilitiesLoaded;

    if (addFleetRoute) {
        routes.push(
            <Route key="fleet" path="/" element={<SecuredRoute />}>
                <Route path="/" element={fleetPage} />
            </Route>
        );
    }

    if (addFleetRoute) {
        routes.push(
            <Route key={GRADE} path={`/${GRADE}`} element={<SecuredRoute />}>
                <Route path={`/${GRADE}`} element={<Grade />} />
            </Route>
        );
    }

    if (addFleetRoute) {
        routes.push(
            <Route key={SUBGRADES} path={`/${SUBGRADES}`} element={<SecuredRoute />}>
                <Route path={`/${SUBGRADES}`} element={<SubGradesPage />} />
            </Route>
        );
    }

    if (addFleetRoute) {
        routes.push(
            <Route key="defects" path={`/${DEFECTS}`} element={<SecuredRoute />}>
                <Route path={`/${DEFECTS}`} element={<Defects />} />
                <Route path=":defectId/rectification" element={<DefectRectification />} />
            </Route>
        );
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS]) {
        routes.push(
            <Route key="rectifications" path="/" element={<SecuredRoute />}>
                <Route path="/" element={<SharedDefects />} />
                <Route path={`/${RECTIFICATIONS}`} element={<SharedDefects />} />
                <Route path=":defectId/rectification" element={<DefectRectification sharedDefect />} />
                <Route path={`/${RECTIFICATIONS}/:defectId/rectification`} element={<DefectRectification sharedDefect />} />
            </Route>
        );
    }

    if (addFleetRoute) {
        routes.push(
            <Route key="psc" path="/" element={<SecuredRoute />}>
                <Route path={`/${PSC}`} element={<PortStateControl />} />
            </Route>
        );
    }

    if (addFleetRoute) {
        routes.push(
            <Route key="screening" path="/" element={<SecuredRoute />}>
                <Route path={`/${SCREENING}`} element={<Screening />} />
            </Route>
        );
    }

    return <Routes>{routes}</Routes>;
}
