import  "./FilterButtonRow.css";
import { Button } from "primereact/button";
import { ShowAllFiltersButton } from "../ShowAllFilters/ShowAllFiltersButton";
import { FilterIcon } from "../Icons/FilterIcon";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { toggleOpenState } from "../../store/thunks/advancedFiltersThunks";
import { GraphFilterSelector, SubgradeGraphFilterType } from "../GraphFilterSelector/GraphFilterSelector";
import {
    selectDefectGraphContextFilter,
    selectGraphContextFilter,
    selectSubGradeGraphContextFilter
} from "../../store/selectors/filtersSelectors";
import { setDefectGraphContextFilter, setGraphContextFilter, setSubGradeGraphContextFilter } from "../../store/slices/filtersSlice";
import { useLocation } from "react-router-dom";
import { storeUserSessionStorageItem } from "../../utils/UserSessionStorage";
import { useFlags } from "launchdarkly-react-client-sdk";
import { graphFilterDropdownOptions, subgradeGraphFilteringOptions } from "./GraphFilterOptions";
import { useMemo } from "react";


export type FilterButtonContainerProps = {
    enableFilterButton: boolean;
}

export const FilterButtonRow = ({
    enableFilterButton
}: FilterButtonContainerProps) => {
    const {t} = useTranslation("locale");

    const { releaseDefectByAreaChartEnhancement ,  releaseGradeChartEnhancement, releaseSubGradeChartEnhancement } = useFlags();

    const location = useLocation();
    const graphPage = location.pathname.split("/")[1];

    const dispatch = useAppDispatch();
    const graphContextFilter = useAppSelector(selectGraphContextFilter);
    const subgradeGraphContextFilter = useAppSelector(selectSubGradeGraphContextFilter);
    const defectGraphContextFilter = useAppSelector(selectDefectGraphContextFilter);

    const onClick = () => {
        dispatch(toggleOpenState());
    }

    const handleGraphFilterChange = (e: any) => {
        dispatch(setGraphContextFilter(e.value));
        storeUserSessionStorageItem("user", "graphContextFilter", e.value);
    }

    const handleSubgradeFilterChange = (e: any) => {
        dispatch(setSubGradeGraphContextFilter(e.value));
    }

    const handleDefectFilterChange = (e: any) => {
        dispatch(setDefectGraphContextFilter(e.value));
    }


    const graphFilterOptions = useMemo(() => graphFilterDropdownOptions(), []);
    const subgradeFilterOptions = useMemo(() => subgradeGraphFilteringOptions(), []);

    const getGraphFilterSelector = () => {
        if (graphPage === "grade" && releaseGradeChartEnhancement) {
            return (
                <GraphFilterSelector 
                    selectedValue={graphContextFilter}
                    onFilterChange={handleGraphFilterChange}
                    options={graphFilterOptions} />
            )
       }

        if (graphPage === "subgrades" && releaseSubGradeChartEnhancement) {
            const sortedSubgradeGraphFilteringOptions = subgradeFilterOptions.sort((a, b) => {
                if (a.code === SubgradeGraphFilterType["All Areas"]) return -1;
                if (b.code === SubgradeGraphFilterType["All Areas"]) return 1;
                return a.name.localeCompare(b.name);
            });
            
            return (
                <GraphFilterSelector 
                    selectedValue={subgradeGraphContextFilter}
                    onFilterChange={handleSubgradeFilterChange}
                    options={sortedSubgradeGraphFilteringOptions} />
            )
        }

        if (graphPage === "defects" && releaseDefectByAreaChartEnhancement) {
            const sortedSubgradeGraphFilteringOptions = subgradeFilterOptions.sort((a, b) => {
                if (a.code === SubgradeGraphFilterType["All Areas"]) return -1;
                if (b.code === SubgradeGraphFilterType["All Areas"]) return 1;
                return a.name.localeCompare(b.name);
            });

            return (
                <GraphFilterSelector
                    selectedValue={defectGraphContextFilter}
                    onFilterChange={handleDefectFilterChange}
                    options={sortedSubgradeGraphFilteringOptions} />
            )
        }

        return <></>;
    }

    return (
        <div className={"filter_button_row__button-container"}>
            <ShowAllFiltersButton />
            <div className="flex align-items-center">
                {getGraphFilterSelector()}
                {
                    enableFilterButton ?
                        <Button
                            className={`p-button p-button-primary w-14rem`}
                            label={t("benchmarkModal.benchmarkFilters")}
                            icon={FilterIcon}
                            onClick={onClick}
                        />
                        :
                        <></>
                }
            </div>
        </div>
    )
}