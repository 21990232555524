import { getTranslationObject } from "../../services/Translation";
import { GraphFilterType, SubgradeGraphFilterType } from "../GraphFilterSelector/GraphFilterSelector";

const t = getTranslationObject();

export const graphFilterDropdownOptions = () => {
    return [
        {
            name: t("fleetGraph.graphFilterSelector.vessels"),
            code: GraphFilterType.VESSELS
        },
        {
            name: t("fleetGraph.graphFilterSelector.technicalManagers"),
            code: GraphFilterType.TECHNICAL_MANAGERS
        }
    ];
};

export const subgradeGraphFilteringOptions = () => {
    return [
        {
            name: t("subgrades.areas.allAreas"),
            code: SubgradeGraphFilterType["All Areas"]
        },
        {
            name: t("subgrades.areas.bridgeAndNavigationEquipment"),
            code: SubgradeGraphFilterType["Bridge and Navigation Equipment"]
        },
        {
            name: t("subgrades.areas.accomodation"),
            code: SubgradeGraphFilterType.Accommodation
        },
        {
            name: t("subgrades.areas.lifesavingAppliances"),
            code: SubgradeGraphFilterType["Lifesaving Appliances"]
        },
        {
            name: t("subgrades.areas.mooringDecks"),
            code: SubgradeGraphFilterType["Mooring Decks"]
        },
        {
            name: t("subgrades.areas.engineRoomAndMachinery"),
            code: SubgradeGraphFilterType["Engine Room and Machinery"]
        },
        {
            name: t("subgrades.areas.vesselCapabilitiesAndCargoSystems"),
            code: SubgradeGraphFilterType["Vessel Capabilities and Cargo Systems"]
        },
        {
            name: t("subgrades.areas.ballastTankAndSystems"),
            code: SubgradeGraphFilterType["Ballast Tanks and Systems"]
        },
        {
            name: t("subgrades.areas.weatherDecksAndFittings"),
            code: SubgradeGraphFilterType["Weather Decks and Fittings"]
        },
        {
            name: t("subgrades.areas.hull"),
            code: SubgradeGraphFilterType.Hull
        },
        {
            name: t("subgrades.areas.pollutionControl"),
            code: SubgradeGraphFilterType["Pollution Control"]
        },
        {
            name: t("subgrades.areas.onboardManagement"),
            code: SubgradeGraphFilterType["Onboard Management"]
        },
        {
            name: t("subgrades.areas.safeWorkingEnvironment"),
            code: SubgradeGraphFilterType["Safe Working Environment"]
        },
        {
            name: t("subgrades.areas.forthcomingRegulatoryCompliance"),
            code: SubgradeGraphFilterType["Forthcoming Regulatory Compliance"]
        },
        {
            name: t("subgrades.areas.crewWelfare"),
            code: SubgradeGraphFilterType["Crew Welfare"]
        },
        {
            name: t("subgrades.areas.crewPerformance"),
            code: SubgradeGraphFilterType["Crew Performance"]
        },
        {
            name: t("subgrades.areas.safetyManagement"),
            code: SubgradeGraphFilterType["Safety Management"]
        },
        {
            name: t("subgrades.areas.plannedMaintenanceSystem"),
            code: SubgradeGraphFilterType["Planned Maintenance System (PMS)"]
        },
        {
            name: t("subgrades.areas.classificationAndCertification"),
            code: SubgradeGraphFilterType["Classification and Certification"]
        },
        {
            name: t("subgrades.areas.pscPerformance"),
            code: SubgradeGraphFilterType["PSC Performance"]
        },
        {
            name: t("subgrades.areas.fireFightingEquipmentAndSystems"),
            code: SubgradeGraphFilterType["Fire Fighting Equipment and Systems"]
        },
        {
            name: t("subgrades.areas.designAndConstruction"),
            code: SubgradeGraphFilterType["Design and Construction"]
        }
    ];
};