import { ReactElement, useEffect, useMemo, useState } from "react";
import Plot from "react-plotly.js";
import { Config, Data } from "plotly.js";
import { errorToast } from "@idwal/idwal-react-components";
import {
    calculateAverageSectionGrade,
    calculateAverageSectionGradeForTechnicalManagers,
    calculateDeviationPercentages,
    calculateTechnicalManagerDeviationPercentages,
    getTrendDecoration,
    mapToGraphAxis,
    mapToGraphAxisForTechnicalManagers,
} from "../../utils/SubgradeDataHelper";
import { useTranslation } from "react-i18next";
import { LoadingGraph } from "../Loading/LoadingGraph";
import "./DeviationGraph.css";
import { VesselSubgradeData, VesselSubgradeResponse } from "../../types/VesselSubgradeResponse";
import { applyGlobalFilter, applyGlobalFilterWithoutVesselName } from "../Shared/GlobalFilter";
import { setDeviationGraphLayout } from "../../utils/DeviationGraphHelper";
import { getSubGradesForGroup } from "../../utils/GroupDropDownHelper";
import { buildGradeGraphTitle, GraphType } from "../../utils/buildGradeGraphTitle";
import { applyBackgroundAdvancedFilters, applyForegroundAdvancedFilters } from "../../utils/AdvancedFilterHelper";
import { useCapabilities } from "../../hooks/useCapabilities";
import { Capability } from "../../types/Capability";
import { getTotalFilterCount } from "../../utils/FilterInformation";
import { ShowAllFiltersDialogAdapter } from "../../componentAdapters/ShowAllFiltersDialogAdapter";
import { FilterButtonRow } from "../FilterButtonRow/FIlterButtonRow";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectAiGradingActive, selectSelectedGroupName } from "../../store/selectors/userSelectors";
import { selectGlobalFilters, selectGlobalFiltersLoading, selectSubGradeGraphContextFilter } from "../../store/selectors/filtersSelectors";
import { selectAdvancedFilters } from "../../store/selectors/advancedFiltersSelectors";
import { selectFleetGraph } from "../../store/selectors/fleetGraphSelectors";
import { selectInspectionAndGradeData } from "../../store/selectors/fleetSelectors";
import { SubgradeGraphFilterType } from "../GraphFilterSelector/GraphFilterSelector";
import { ViewAllGrades } from "../Shared/ViewAllGrades";
import { setSubGradeGraphContextFilter } from "../../store/slices/filtersSlice";

export interface VesselDropdownItem {
    name: string;
    code: string;
}

export const DeviationGraph = (): ReactElement => {
    const {t} = useTranslation("locale");
    const dispatch = useAppDispatch();

    const globalFilters = useAppSelector(selectGlobalFilters);
    const globalFiltersLoading = useAppSelector(selectGlobalFiltersLoading);
    const aiGradingActive = useAppSelector(selectAiGradingActive);
    const selectedGroupName = useAppSelector(selectSelectedGroupName);
    const subgradeGraphContextFilter = useAppSelector(selectSubGradeGraphContextFilter);

    const [layout, setLayout] = useState<any>(null);
    const [xValues, setXValues] = useState<number[] | undefined>(undefined);
    const [yValues, setYValues] = useState<string[] | undefined>(undefined);

    const [filteredData, setFilteredData] = useState<VesselSubgradeResponse>();
    const [showAllVisible, setShowAllVisible] = useState<boolean>(false);

    const { 
        appliedFilters, 
        totalForegroundFilterCount,
        totalBackgroundFilterCount,
    } = useAppSelector(selectAdvancedFilters);

    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_ADVANCED_BENCHMARKING
    ]);

    const hasAdvancedBenchmarkingCapability = capabilities.CAP_FLEETAPP_ADVANCED_BENCHMARKING;

    const {
        subgradeData,
        subgradeError,
        subgradeLoading,
        subgradeBackingData,
        subgradeBackingLoading,
        subgradeBackingError,
    } = useAppSelector(selectFleetGraph);

    const enableBackgroundQuery = hasAdvancedBenchmarkingCapability ? appliedFilters.marketDataToggleSelected : true;

    const inspectionAndGradeData = useAppSelector(selectInspectionAndGradeData);

    const [annotations, setAnnotations] = useState<any[]>([]);

    const useFleetAsBackgroundData = 
        (hasAdvancedBenchmarkingCapability && !appliedFilters.marketDataToggleSelected) 

    useEffect(() => {
        if (!subgradeLoading && !subgradeBackingLoading && subgradeData && (!enableBackgroundQuery || subgradeBackingData) && inspectionAndGradeData) {
            let groupSubGrades: {
                [key: string]: VesselSubgradeData
            } = getSubGradesForGroup(selectedGroupName, subgradeData, inspectionAndGradeData);

            setAnnotations([]);

            let filteredSubgradeData: VesselSubgradeResponse = {};

            Object.entries(groupSubGrades).forEach(x => {
                const imo = x[0];
                const subgradeDataForVessel = x[1];

                if (applyFilter(subgradeDataForVessel)) {
                    filteredSubgradeData[imo] = subgradeDataForVessel;
                }
            });

            setFilteredData(filteredSubgradeData);
            const sectionAverages = calculateAverageSectionGrade(filteredSubgradeData);

            let backgroundData;
            if (useFleetAsBackgroundData) {
                let filteredComparisonData: VesselSubgradeResponse = {};

                Object.entries(groupSubGrades).forEach(x => {
                    const imo = x[0];
                    const subgradeDataForVessel = x[1];


                    if (applyFiltersToBackgroundData(subgradeDataForVessel)) {
                        filteredComparisonData[imo] = subgradeDataForVessel;
                    }
                });

                backgroundData = calculateAverageSectionGrade(filteredComparisonData);
            } else {
                backgroundData = subgradeBackingData;
            }

            const overallDeviations = calculateDeviationPercentages(sectionAverages, backgroundData);
            const trendData: { [section: string]: number } = {};

            if (Object.keys(filteredSubgradeData).length == 1) {
                const arrayKey = parseInt(Object.keys(filteredSubgradeData)[0]);
                filteredSubgradeData[arrayKey].subGrades.forEach((x) => {
                    trendData[x.name] = x.trend;
                })
            }

            if (subgradeGraphContextFilter === SubgradeGraphFilterType["All Areas"]) {
                const graphData = mapToGraphAxis(overallDeviations, sectionAverages, trendData);
                setXValues(graphData.y);
                setYValues(graphData.x);
                setLayout(setDeviationGraphLayout(sectionAverages, annotations, trendData));
            } else {
                const selectedSection = SubgradeGraphFilterType[subgradeGraphContextFilter];
                const technicalManagerAverages = calculateAverageSectionGradeForTechnicalManagers(filteredSubgradeData, selectedSection);
                const deviations = calculateTechnicalManagerDeviationPercentages(
                    technicalManagerAverages, (backgroundData as { [section: string]: number })[selectedSection]);
                const graphData = mapToGraphAxisForTechnicalManagers(deviations, technicalManagerAverages);

                if (graphData.x.length > 0 && graphData.y.length > 0 && sectionAverages[selectedSection]) {
                    // Append selected grading area to graph data
                    graphData.y.unshift(overallDeviations[selectedSection]);
                    graphData.x.unshift(`<span style="font-weight: bold">${selectedSection}: ${sectionAverages[selectedSection].toFixed()}${trendData[selectedSection] !== undefined && trendData[selectedSection] !== null ? getTrendDecoration(trendData[selectedSection].toString()) : ""}</span>`);
                }

                setXValues(graphData.y);
                setYValues(graphData.x);

                const newLayout = setDeviationGraphLayout(technicalManagerAverages, null, trendData);
                newLayout.margin.t = 0;
                setLayout(newLayout);
            }
        }
    }, [globalFilters, subgradeData, subgradeBackingData, selectedGroupName, inspectionAndGradeData, appliedFilters, subgradeGraphContextFilter]);

    const config = useMemo(() => {
        const graphConfig: Partial<Config> = {
            displayModeBar: true,
            responsive: true,
            showTips: false,
            displaylogo: false,
            modeBarButtonsToRemove: ['pan2d','zoom2d','autoScale2d','resetScale2d' ,'lasso2d','select2d']
        }
        return graphConfig;
    }, []);

    const applyFilter = (dataItem: VesselSubgradeData) => {
        return applyGlobalFilter(globalFilters, dataItem.vesselName ?? "", dataItem.shipTypeName, dataItem.technicalManager, dataItem.shipAge) && applyForegroundAdvancedFilters(
            appliedFilters,
            {
                aiGradingActive,
                countryOfBuild: dataItem.countryOfBuild,
                technicalManager: dataItem.technicalManager,
                dwt: dataItem.dwt,
                teu: dataItem.teu,
                lastInspected: dataItem.inspectionDate,
                vesselHasAiGrades: false
            },
            t
        );
    }

    // This is for when filtering against "My Fleet" as background data. Market data is filtered in API.
    const applyFiltersToBackgroundData = (dataItem: VesselSubgradeData) => {
        const vesselswithGlobalFiltersApplied = applyGlobalFilterWithoutVesselName(globalFilters, dataItem.shipTypeName, dataItem.shipAge)

        if (useFleetAsBackgroundData) {
            return vesselswithGlobalFiltersApplied && applyBackgroundAdvancedFilters(
                appliedFilters,
                {
                    aiGradingActive,
                    countryOfBuild: dataItem.countryOfBuild,
                    technicalManager: dataItem.technicalManager,
                    dwt: dataItem.dwt,
                    teu: dataItem.teu,
                    lastInspected: dataItem.inspectionDate,
                    vesselHasAiGrades: false,
                    vessel: dataItem.vesselName
                },
                t
            );
        } else {
            return vesselswithGlobalFiltersApplied;
        }
    }

    if (subgradeError || subgradeBackingError) {
        if (subgradeBackingError) {
            console.error(subgradeBackingError);
        }

        errorToast(t("subgrades.graphError"));
        return <div/>;
    }

    const dataIsLoading = subgradeLoading || subgradeBackingLoading || globalFiltersLoading;
    const graphDataIsLoading = dataIsLoading || filteredData === undefined || xValues === undefined || yValues === undefined;
    if (graphDataIsLoading) {
        return (<LoadingGraph/>);
    }

    const deviationBoundaries = {
        unsatisfactory: {
            upper: -4,
            label: "unsatisfactory",
            color: "#28235B",
        },
        poor: {
            lower: -4,
            upper: -1.5,
            label: "poor",
            color: "#484375",
        },
        fair: {
            lower: -1.5,
            upper: 1.5,
            label: "fair",
            color: "#186AA5",
        },
        good: {
            lower: 1.5,
            upper: 4,
            label: "good",
            color: "#009EB2",
        },
        veryGood: {
            lower: 4,
            label: "veryGood",
            color: "#00C7CC",
        }
    };

    const textLabels = xValues.map(value => value.toFixed(2) + '%');

    function createTrace(xFilter: any, yFilter: any, textFilter: any, color: string, label: string) {
        return {
            type: "bar",
            x: xValues!.filter(xFilter),
            y: yValues!.filter(yFilter),
            text: textLabels.filter(textFilter),
            orientation: "h",
            hoverinfo: "none",
            marker: {
                color: color,
            },
            texttemplate: "%{text}",
            textposition: "auto",
            name: t(`subgrades.deviations.${label}`),
        };
    }

    const traceConfigurations = [
        {
            filter: (value: number) => value <= deviationBoundaries.unsatisfactory.upper,
            color: deviationBoundaries.unsatisfactory.color,
            label: deviationBoundaries.unsatisfactory.label,
        },
        {
            filter: (value: number) => value > deviationBoundaries.poor.lower && value <= deviationBoundaries.poor.upper,
            color: deviationBoundaries.poor.color,
            label: deviationBoundaries.poor.label,
        },
        {
            filter: (value: number) => value > deviationBoundaries.fair.lower && value <= deviationBoundaries.fair.upper,
            color: deviationBoundaries.fair.color,
            label: deviationBoundaries.fair.label,
        },
        {
            filter: (value: number) => value > deviationBoundaries.good.lower && value <= deviationBoundaries.good.upper,
            color: deviationBoundaries.good.color,
            label: deviationBoundaries.good.label,
        },
        {
            filter: (value: number) => value > deviationBoundaries.veryGood.lower,
            color: deviationBoundaries.veryGood.color,
            label: deviationBoundaries.veryGood.label,
        },
    ];

    const data: Data[] = traceConfigurations.map(configuration => createTrace(
        (value: number) => configuration.filter(value),
        (_: any, index: number) => configuration.filter(xValues[index]),
        (_: any, index: number) => configuration.filter(xValues[index]),
        configuration.color,
        configuration.label
    )) as Data[];

    if (annotations && annotations.length > 0) {
        layout.annotations = annotations;
    }

    const graphTitle = buildGradeGraphTitle({
        mode: GraphType.SUBGRADE,
        globalFilterState: globalFilters,
        marketDataToggleSelected: appliedFilters.marketDataToggleSelected,
        t
    });

    const toggleShowAllFiltersModal = () => {
        setShowAllVisible(!showAllVisible);
    }

    const totalFilterCount = getTotalFilterCount(
        totalForegroundFilterCount,
        totalBackgroundFilterCount
    );

    const calculateDyanmicGraphHeight = () => {
        if (subgradeGraphContextFilter === SubgradeGraphFilterType["All Areas"]) {
            return "";
        }
        const height = xValues.length * 5;
        const heightMin = 20;
        return height < heightMin ? heightMin : height;
    }

    const resetContextFilter = () => {
        dispatch(setSubGradeGraphContextFilter(SubgradeGraphFilterType["All Areas"]));
    }

    const drawPlotly = () => {
        const filteredForegroundAndBackgroundEqual = xValues.every(x => x === 0) && (totalFilterCount > 0 && appliedFilters?.marketDataToggleSelected !== true);
        const canRender = filteredData !== undefined && Object.keys(filteredData).length !== 0 && xValues.length > 0 && yValues.length > 0 && !filteredForegroundAndBackgroundEqual;
        if (canRender) {
            return <>
                <Plot
                    useResizeHandler={true}
                    data={data}
                    layout={layout}
                    config={config}
                    className={`w-full ${subgradeGraphContextFilter === SubgradeGraphFilterType["All Areas"] ? "h-full" : ""}`}
                    style={{height: `${calculateDyanmicGraphHeight()}vh`}}
                />
                <div className="flex align-items-center justify-content-center pb-4"
                    style={{backgroundColor: "#FCFCFC"}}>
                    <img src="deviation_graph_key.svg"/>
                </div>
            </>
        }

        const errorMessage = filteredForegroundAndBackgroundEqual ? t("subgrades.foregroundAndBackgroundAreEqual") : t("subgrades.noVesselSubgradeDataAvailable");
        return <div className="flex justify-content-center"><p>{errorMessage}</p></div>
    }

    return (
        <>
            <ShowAllFiltersDialogAdapter 
                visible={showAllVisible}
                setVisible={toggleShowAllFiltersModal}
            />
            <>
                <FilterButtonRow
                    enableFilterButton={hasAdvancedBenchmarkingCapability}
                />
                <div className={"flex align-items-center justify-content-center"}>
                    <p className="text-center mb-4">
                        {graphTitle}
                    </p>
                </div>
            </>
            {drawPlotly()}
        </>
    );
}